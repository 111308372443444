<div [formGroup]="form">
  <cg-olb-headline
    [title]="'saveDialogContent.title' | transloco"
    [subtitle]="'saveDialogContent.subtitle' | transloco"
  ></cg-olb-headline>

  <div class="p-content-xs d:p-content-desktop">
    <cg-lpn-input class="lpn mb-4 block t:mb-6"></cg-lpn-input>
    <cg-text-input
      class="mb-4 block max-w-none t:max-w-[18.75rem]"
      [content]="inputFields.emailInput"
      [formControlName]="'email'"
      [specificInputType]="InputType.E_MAIL"
    ></cg-text-input>
    <cg-checkbox class="block" [content]="checkboxContent" [formControlName]="'accept'"></cg-checkbox>
  </div>
  <button
    class="part-[icon-text-wrapper]:px-4"
    cg-base-button
    [btnText]="'saveDialogContent.saveBtnTitle' | transloco"
    [icon]="ctaButtonIcon"
    (click)="onSend()"
    data-cy="save-dialog-content-button"
  ></button>
</div>
