import { Feature } from "@cg/feature-toggle";
import { NewAppointmentComponent } from "@cg/olb/tiles";
import type { ConditionalComponentResolverMapping } from "@cg/olb/shared";
// import { OptimizelyExperiment } from "@cg/core/enums";
// import { InsuranceTypeVariantComponent } from "@cg/olb/tiles";

export const conditionalProcessFlow: ConditionalComponentResolverMapping = {
  // Example entry to show how to use the resolver mapping.
  // "insurance-type": {
  //   optimizely: {
  //     [OptimizelyExperiment.INSURANCE_TYPE_AB206]: {
  //       variations: [
  //         {
  //           variationId: "22855132146", // UAT
  //           component: InsuranceTypeVariantComponent
  //         },
  //         {
  //           variationId: "22840601949", // PROD
  //           component: InsuranceTypeVariantComponent
  //         }
  //       ]
  //     }
  //   },
  //  },
  //  appointment: {
  //    feature: {
  //      [Feature.NEW_APPOINTMENT_TILE]: {
  //          component: NewAppointmentComponent
  //      }
  //    },
  //  }
  //
  appointment: {
    feature: {
      [Feature.NEW_APPOINTMENT_TILE]: {
        component: NewAppointmentComponent
      }
    }
  }
};
