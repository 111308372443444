import {
  type ConditionalComponentResolverMapping,
  OLB_PROCESS_FLOW_CONDITIONAL_TILE_MAPPING,
  OLB_PROCESS_FLOW_MODEL,
  OLB_PROCESS_FLOW_PREFIX_COMPONENT_MAPPING,
  OLB_PROCESS_FLOW_TILE_MAPPING,
  type ProcessFlowBase,
  type ProcessFlowTileMapping,
  type ProcessNodeBase
} from "@cg/olb/shared";
import type { EnvironmentProviders, Provider } from "@angular/core";

export function provideOlbCore(
  flowModel: ProcessFlowBase<string, ProcessNodeBase<string, string>>,
  tileMapping: ProcessFlowTileMapping<string>,
  prefixMapping: ProcessFlowTileMapping<string>,
  abTestTileMapping?: ConditionalComponentResolverMapping
): Provider[] | EnvironmentProviders[] {
  return [
    { provide: OLB_PROCESS_FLOW_MODEL, useValue: flowModel },
    { provide: OLB_PROCESS_FLOW_TILE_MAPPING, useValue: tileMapping },
    { provide: OLB_PROCESS_FLOW_PREFIX_COMPONENT_MAPPING, useValue: prefixMapping },
    { provide: OLB_PROCESS_FLOW_CONDITIONAL_TILE_MAPPING, useValue: abTestTileMapping ?? {} }
  ];
}
