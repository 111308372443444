<div id="olb-flow" class="w-full">
  @if (processBarVisible) {
    <div
      class="sticky left-0 right-0 top-0 z-50 w-full bg-cg-gray-light-70 px-2 shadow-progress-bar t:px-6 w:px-[2.875rem]"
      [@hideAnimation]
    >
      <div id="olb-progress-bar" class="box-border flex h-[2.75rem] w-full flex-col items-center justify-center">
        <div
          class="box-border flex w-full flex-row items-center px-4 t:px-16 d:px-[2.625rem] w:max-w-5xl w:px-[3.875rem]"
        >
          <cg-progressbar class="w-full" [progress]="processPercentage$ | async"></cg-progressbar>
          @if (activeSaveAndRestore) {
            <cg-save-button tabindex="0" class="py-2.5 pl-6 m:pl-4 d:pl-6" (click)="onSaveClick()"></cg-save-button>
          }
        </div>
      </div>
    </div>
  }
  <div
    class="box-border flex w-full flex-col items-center overflow-clip p-main-xs m:p-main-mobile t:p-main-tablet w:p-main-desktop fhd:p-main-fullhd"
  >
    @for (metaData of processMetaData$ | async; track trackByFn($index, metaData)) {
      <cg-olb-step
        class="mt-step-xs w-full m:mt-step-mobile t:mt-step-tablet d:mt-step-desktop w:mx-auto w:mt-step-widescreen w:max-w-5xl"
        [processMetadata]="metaData"
        [attr.data-process-id]="metaData.id"
      ></cg-olb-step>
    }
    <div id="arrow-hint" class="[&>cg-arrow-hint]:my-10 [&>cg-arrow-hint]:block"></div>
  </div>
  <cg-spinner></cg-spinner>
</div>
