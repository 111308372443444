/* eslint-disable sonarjs/no-duplicate-string */
import type { ProcessFlow } from "@cg/olb/shared";

export const licensePlateExitNodes: Partial<Record<string, string>> = {
  appointment: "appointment",
  duplicate: "appointment-already-exists",
  channelSwitch: "channel-switch",
  vin: "vehicle-identification-number",
  "car-identified": "car-identified",
  "tesla-restrictions": "tesla-restrictions",
  noProductFoundOpportunityAbTest: "opportunity-funnel-product-not-found"
};

export const processFlow: ProcessFlow = {
  "damage-window": {
    prefixComponent: "easiness-of-process",
    component: "damage-window",
    exitNodes: {
      windscreenDamaged: "damage-type",
      skylightDamaged: "channel-switch",
      otherWindowDamaged: "channel-switch",
      sideWindowDamaged: "opportunity-funnel-damage-side",
      rearWindowDamaged: "opportunity-funnel-damage-rear",
      multipleWindowsDamaged: "channel-switch",
      noPrimeJob: "channel-switch"
    }
  },
  "damage-location": {
    component: "damage-location",
    exitNodes: {
      fieldOfViewOrEdgeDamaged: "insurance-type",
      otherLocationDamaged: "damage-size",
      channelSwitch: "channel-switch"
    }
  },
  "damage-location-multiple-chips": {
    component: "damage-location-multiple-chips",
    exitNodes: {
      fieldOfViewOrEdgeDamaged: "insurance-type",
      otherLocationDamaged: "insurance-type"
    }
  },
  "damage-type": {
    component: "damage-type",
    exitNodes: {
      majorStoneChipCrackDamageType: "insurance-type",
      minorStoneChipDamageType: "damage-location",
      multipleChipsDamageType: "damage-chip-count",
      channelSwitch: "channel-switch",
      "tesla-appointment": "tesla-appointment"
    }
  },
  "damage-size": {
    component: "damage-size",
    exitNodes: {
      bigSizeDamage: "insurance-type",
      smallSizeDamage: "insurance-type",
      channelSwitch: "channel-switch",
      "tesla-appointment": "tesla-appointment"
    }
  },
  "damage-size-multiple-chips": {
    component: "damage-size-multiple-chips",
    exitNodes: {
      bigSizeDamage: "insurance-type",
      smallSizeDamage: "damage-location-multiple-chips",
      "tesla-appointment": "tesla-appointment"
    }
  },
  "damage-chip-count": {
    component: "damage-chip-count",
    exitNodes: {
      twoOrThreeDamageChipCount: "damage-size-multiple-chips",
      moreThanThreeDamageChipCount: "insurance-type",
      "tesla-appointment": "tesla-appointment"
    }
  },
  "license-plate/gdv-and-fin-for-replace": {
    component: "license-plate/gdv-and-fin-for-replace",
    exitNodes: licensePlateExitNodes
  },
  "license-plate/no-gdv-for-replace": {
    component: "license-plate/no-gdv-for-replace",
    exitNodes: licensePlateExitNodes
  },
  "license-plate/default": {
    component: "license-plate/default",
    exitNodes: licensePlateExitNodes
  },
  "license-plate/no-date-no-gdv": {
    component: "license-plate/no-date-no-gdv",
    exitNodes: licensePlateExitNodes
  },
  "license-plate/default-with-name": {
    component: "license-plate/default-with-name",
    exitNodes: licensePlateExitNodes
  },
  "vehicle-identification-number": {
    component: "vehicle-identification-number",
    exitNodes: {
      appointment: "appointment",
      channelSwitch: "channel-switch",
      noProductFound: "vehicle-identification-manually",
      noProductFoundOpportunityAbTest: "opportunity-funnel-product-not-found",
      "car-identified": "car-identified",
      "tesla-restrictions": "tesla-restrictions"
    }
  },
  "vehicle-identification-number-manually": {
    component: "vehicle-identification-number-manually",
    exitNodes: {
      appointment: "appointment",
      channelSwitch: "channel-switch",
      noProductFound: "vehicle-identification-manually",
      noProductFoundOpportunityAbTest: "opportunity-funnel-product-not-found",
      "car-identified": "car-identified",
      "tesla-restrictions": "tesla-restrictions"
    }
  },
  "vehicle-identification-manually": {
    component: "vehicle-identification-manually",
    exitNodes: {
      appointment: "appointment",
      channelSwitch: "channel-switch",
      "car-identified": "car-identified",
      "tesla-restrictions": "tesla-restrictions",
      noProductFoundOpportunityAbTest: "opportunity-funnel-product-not-found"
    }
  },
  "damage-assessment-result": {
    component: "damage-assessment-result",
    passthrough: true,
    exitNodes: {}
  },
  appointment: {
    component: "appointment",
    passthroughId: "damage-assessment-result", // in case of direct resume we show damage assessment result here
    exitNodes: {
      vapsOffer: "vaps-offer",
      emailQuery: "customer-contact",
      mobileService: "channel-switch",
      channelSwitch: "channel-switch",
      directResume: "vaps-offer",
      newCustomer: "customer-address"
    }
  },
  "customer-contact": {
    component: "customer-contact",
    exitNodes: {
      newCustomer: "customer-address",
      directResume: "summary",
      opportunityFunnelContactTime: "opportunity-funnel-contact-time"
    }
  },
  "customer-address": {
    component: "customer-address",
    exitNodes: {
      appointmentSuccess: "appointment-confirmation",
      bindingBooking: "binding-booking",
      summary: "summary",
      opportunityFunnelContactTime: "opportunity-funnel-contact-time"
    }
  },
  "vaps-offer": {
    component: "vaps-offer",
    exitNodes: {
      customerContact: "customer-contact",
      directResume: "summary"
    }
  },
  "appointment-confirmation": {
    component: "appointment-confirmation",
    exitNodes: {}
  },
  "appointment-already-exists": {
    component: "appointment-already-exists",
    exitNodes: {}
  },
  "olb-technical-error": {
    component: "olb-technical-error",
    exitNodes: {}
  },
  "insurance-type": {
    component: "insurance-type",
    passthroughId: "damage-assessment-result",
    exitNodes: {
      default: "license-plate/default",
      noGdvForReplace: "license-plate/no-gdv-for-replace",
      gdvAndFinForReplace: "license-plate/gdv-and-fin-for-replace",
      noDateNoGdv: "license-plate/no-date-no-gdv",
      withName: "license-plate/default-with-name",
      channelSwitch: "channel-switch"
    }
  },
  "channel-switch": {
    component: "channel-switch",
    exitNodes: {}
  },
  "binding-booking": {
    component: "binding-booking",
    exitNodes: {
      appointmentSuccess: "appointment-confirmation",
      summary: "summary"
    }
  },
  "car-identified": {
    component: "car-identified",
    exitNodes: {
      appointment: "appointment"
    }
  },
  "opportunity-funnel-damage-side": {
    component: "opportunity-funnel-damage-side",
    exitNodes: {
      default: "opportunity-funnel-license-plate",
      directResume: "opportunity-funnel-costs"
    }
  },
  "opportunity-funnel-damage-rear": {
    component: "opportunity-funnel-damage-rear",
    exitNodes: {
      default: "opportunity-funnel-license-plate",
      directResume: "opportunity-funnel-costs"
    }
  },
  "opportunity-funnel-costs": {
    prefixComponent: "opportunity-funnel-damage-assessment-result",
    component: "opportunity-funnel-costs",
    exitNodes: {
      default: "opportunity-funnel-appointment-request",
      channelSwitch: "opportunity-funnel-channel-switch"
    }
  },
  "opportunity-funnel-license-plate": {
    prefixComponent: "opportunity-funnel-damage-assessment-result",
    component: "opportunity-funnel-license-plate",
    exitNodes: {
      default: "opportunity-funnel-costs"
    }
  },
  "opportunity-funnel-appointment-request": {
    component: "opportunity-funnel-appointment-request",
    exitNodes: {
      default: "opportunity-funnel-customer-address",
      channelSwitch: "opportunity-funnel-channel-switch",
      directResume: "opportunity-funnel-contact-time",
      newCustomer: "customer-address",
      emailQuery: "customer-contact"
    }
  },
  "opportunity-funnel-customer-address": {
    component: "opportunity-funnel-customer-address",
    exitNodes: {
      default: "opportunity-funnel-contact-time"
    }
  },
  "opportunity-funnel-contact-time": {
    component: "opportunity-funnel-contact-time",
    exitNodes: {
      default: "opportunity-funnel-summary"
    }
  },
  "opportunity-funnel-summary": {
    component: "opportunity-funnel-summary",
    exitNodes: {
      default: "opportunity-funnel-success",
      channelSwitch: "opportunity-funnel-channel-switch"
    }
  },
  "opportunity-funnel-success": {
    component: "opportunity-funnel-success",
    exitNodes: {}
  },
  "opportunity-funnel-product-not-found": {
    component: "opportunity-funnel-product-not-found",
    exitNodes: {
      default: "opportunity-funnel-appointment-request",
      channelSwitch: "opportunity-funnel-channel-switch"
    }
  },
  "opportunity-funnel-channel-switch": {
    component: "channel-switch",
    exitNodes: {}
  },
  "tesla-restrictions": {
    component: "tesla-restrictions",
    exitNodes: {
      appointment: "tesla-appointment",
      directResume: "vaps-offer"
    }
  },
  "tesla-appointment": {
    component: "tesla-appointment",
    passthroughId: "damage-assessment-result", // in case of direct resume we show damage assessment result here
    exitNodes: {
      emailQuery: "customer-contact",
      vapsOffer: "vaps-offer"
    }
  },
  summary: {
    component: "summary",
    exitNodes: {
      appointmentSuccess: "appointment-confirmation"
    }
  }
};
